.alert-pms7462 {
  background-color: #00538b;
  color: white;
}
.alert-pms2955 {
  background-color: #003764;
  color: white;
}
.alert-pms7752 {
  background-color: #d6b861;
  color: white;
}
.alert-pms293 {
  background-color: #0061af;
  color: white;
}
.alert-pms173 {
  background-color: #d2451e;
  color: white;
}
.alert-pms425 {
  background-color: #53575a;
  color: white;
}
.alert-pms292 {
  background-color: #65b2e8;
  color: white;
}
/*-- footer component --*/
.AppFooter-footer {
  color: #fff;
  display: flex;
  padding: 16px;
  flex-flow: row wrap;
  background: #0061af;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}
.AppFooter-footer a {
  padding: 8px 4px;
}
.AppFooter-footer a:hover {
  color: white;
}
.AppFooter-subFooter {
  color: rgba(255, 255, 255, 0.7);
  padding: 16px;
  background: #003764;
  text-align: center;
}
.AppFooter-footer a:not(:last-child)::after {
  content: '|';
  margin-left: 8px;
}

/*-- search component */
:root {
  --primary: rgb(0, 55, 100);
  --secondary: rgb(0, 97, 175);
  --tertionary: rgb(0, 83, 139);
  --info: rgb(101, 178, 232);
  --danger: rgb(210, 69, 30);
  --danger-second: rgba(210, 69, 30, 0.8);
  --warning: rgb(214, 184, 97);
  --warning-second: rgba(214, 184, 97, 0.8);
  --dark: rgb(83, 87, 90);
  --dark-second: rgba(83, 87, 90, 0.8);
  --border: rgba(0, 97, 175, 0.1);
  --shadow-btn: 0 4px 8px 2px rgb(88 136 176 / 15%);
  --shadow-btn-hover: 0 8px 16px 5px rgb(88 136 176 / 15%);
  --shadow-card: rgba(88, 136, 176, 0.15);
  --light-bg: #f1f1f1;
  --default-bg: #f6f7f9;
  --font-xl: 1.25rem;
  --font-lg: 1rem;
  --font-md: 0.9375rem;
  --font-sm: 0.8rem;
  --font-xs: 0.72rem;
  --font-badge: 0.5rem;
}

.challenge-question-card h4{
  margin-left: 10px !important;
  margin-top: 5px !important;
  color: var(--secondary);
}

.challenge-question-card{
  padding-bottom: 30px;
}

.challenge-question-card h5{
  margin-top: 8px;
}

.challenge-question-card h5, .challenge-question-card .MuiInput-formControl, .challenge-question-card select, .challenge-question-card .form-control-error {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

h4 h5 input select

.card {
  background-color: rgb(255, 255, 255);
  border: none;
  border-bottom: 2px solid rgb(239, 240, 245);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.card-body {
  padding: 0;
}

.card-body .content {
  padding: 1rem 1.25rem;
}
.list-group .badge {
  font-size: var(--font-badge);
}
.list-group-item {
  border: none;
  border-top: 1px solid var(--border) !important;
  color: var(--secondary);
  font-size: var(--font-md);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
}

.list-group-item:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: rgba(100, 176, 232, 0.1);
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
  -moz-transition: linear 0.25s;
  -ms-transition: linear 0.25s;
  -o-transition: linear 0.25s;
}

.list-group-item .item-title {
  font-size: var(--font-sm);
  font-weight: 600;
}
.list-group-item .item-body {
  font-size: var(--font-xs);
  font-weight: 400;
  color: var(--dark);
  margin-bottom: 4px;
}
.list-group-item .item-bottom small {
  font-size: var(--font-xs);
  font-weight: 600;
  color: var(--dark);
}
.list-group-item .icon {
  opacity: 0;
  margin-top: 2px;
}
.list-group-item:hover .icon {
  opacity: 1;
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
  -moz-transition: linear 0.25s;
  -ms-transition: linear 0.25s;
  -o-transition: linear 0.25s;
}

.list-group-item .link-visual {
  margin-top: 2px;
  text-align: right;
  padding-right: 0;
}
.list-group-item .link-visual span {
  opacity: 0;
  font-size: var(--font-xs);
}
.list-group-item:hover .link-visual span {
  opacity: 1;
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
  -moz-transition: linear 0.25s;
  -ms-transition: linear 0.25s;
  -o-transition: linear 0.25s;
}
a.list-group-item {
  font-size: var(--font-sm);
}

.page-link {
  font-size: var(--font-sm);
  color: var(--secondary);
  padding: 0.25rem 0.5rem;
  text-align: center;
  margin-left: 4px;
  margin-right: 4px;
  border: none;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.page-link.active {
  background-color: var(--light-bg);
  border-color: var(--light-bg);
}

.page-link-pagination {
  font-size: var(--font-sm);
  background-color: var(--light-bg);
  border-color: var(--light-bg);
  color: var(--dark);
  cursor: pointer;
}
.link-box {
  display: inline-flex;
  padding: 1rem 1.25rem;
}
.link-box svg {
  max-width: 64px;
  min-height: 52px;
}

.list-group a {
  text-decoration: none;
}
/*-- search component */
/* -- Card component --*/
.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 26.9325px;
  padding: 1rem 1.25rem;
  margin-bottom: 0;
}
.card-image {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  color: white;
  border-radius: 4px 4px 0px 0px;
  -webkit-border-radius: 4px 4px 0px 0px;
  -moz-border-radius: 4px 4px 0px 0px;
  -ms-border-radius: 4px 4px 0px 0px;
  -o-border-radius: 4px 4px 0px 0px;
}
.btn {
  box-shadow: var(--shadow-btn);
}
.btn:hover {
  box-shadow: var(--shadow-btn-hover);
  color: #fff;
}
.btn-blue {
  background: linear-gradient(
    316.31deg,
    var(--secondary) 0%,
    var(--primary) 100%
  );
  border: var(--primary);
  color: #fff;
  background-color: #00538b;
  border-color: #00538b;
}
.btn-blue:hover {
  background: #3375a2;
}

.btn-orange {
  background: linear-gradient(
    316.31deg,
    var(--danger-second) 0%,
    var(--danger) 100%
  );
  border: var(--danger);
  color: #fff;
  background-color: #d2451e;
  border-color: #d2451e;
}
.btn-orange:hover {
  background: #db6a4b;
}

.pl-20 {
  padding-left: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.elev-3 {
  box-shadow: 0 8px 16px 5px var(--shadow-card);
}
.arrowIcon {
  display: none;
}
.list-group-item:hover > a > .arrowIcon {
  display: block;
}
.display-block{
  display: block !important;
}
.wysiwyg {
  padding: 0.75rem 1.25rem;
}
/* hero image and carousel */
.carousel-title {
  font-weight: 600;
  font-size: 4vw;
  color: #ffffff;
  text-shadow: 2px 2px 5px #000;
}
.overlay-text {
  color: #333 !important;
}

.hero-container {
  position: relative;
}
.hero-overlay {
  position: absolute;
  top: 15%;
  left: 15%;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 4vw;
  text-shadow: 2px 2px 5px #000;
}
.hero-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}
/*--added to make buttons appear on top --*/
.carousel-control-next {
  z-index: 99;
}
.carousel-control-previous {
  z-index: 99;
}
/* card updates */
.elev-2 {
  box-shadow: 0 4px 8px 4px var(--shadow-card);
}
.br-left-primary {
  border-left: 6px solid var(--primary);
}
a {
  color: #003da5;
}
h1 {
  font-size: 2rem;
  line-height: 1.125;
}
h2 {
  font-size: 1.875rem;
  line-height: 1;
}
/* card h2 override - story #245380 */
.card h2 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 26.9325px;
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  padding: 0;
}
h3 {
  font-size: 1.375rem;
  line-height: 1.25;
}
h4 {
  font-size: 1.125rem;
  line-height: 1.33;
}
h5 {
  font-size: var(--font-md);
  line-height: 1.33;
}
h6 {
  font-size: 0.9rem;
  line-height: 1.6;
}

.card .card-body .list-group-item {
  padding-right: 30px;
}
.card .card-body .list-group-item svg {
  margin-right: -15px;
}
/* -- tabbed content --*/
#tabbed-content .MuiDivider-root {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: var(--border);
}
.nav.nav-tabs.v1 {
  border-bottom: 3px solid var(--secondary);
}
.nav-tabs .nav-link {
  color: var(--dark);
  opacity: 0.9;
  min-width: 120px;
  margin-right: 2px;
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
  font-size: var(--font-xs);
  font-weight: 600;
  padding: 0.5rem 1rem;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.nav.nav-tabs.v1 .nav-link.active {
  color: white;
  background-color: var(--secondary);
  box-shadow: 0 4px 8px 4px var(--shadow-card);
  border-radius: 4px 4px 0px 0px;
  -webkit-border-radius: 4px 4px 0px 0px;
  -moz-border-radius: 4px 4px 0px 0px;
  -ms-border-radius: 4px 4px 0px 0px;
  -o-border-radius: 4px 4px 0px 0px;
}
.tab-content {
  margin-top: 2rem;
}
.btn-primary {
  background: linear-gradient(
    316.31deg,
    var(--secondary) 0%,
    var(--primary) 100%
  );
  border: var(--primary);
}
.btn-primary:hover {
  background: var(--primary);
}
.btn-danger {
  background: linear-gradient(
    316.31deg,
    var(--danger-second) 0%,
    var(--danger) 100%
  );
  border: var(--danger);
}
.btn-danger:hover {
  background: var(--danger);
}
.btn-blue:focus,
.btn-blue.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-blue.disabled,
.btn-blue:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-orange:focus,
.btn-orange.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-orange.disabled,
.btn-orange:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
/* X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .second-nav .bg {
    height: 80px;
  }
  .second-nav .menu {
    margin-top: -2rem;
    padding: 1rem;
  }
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .second-nav .bg {
    height: 60px;
  }
  .second-nav .menu {
    margin-top: -2rem;
    padding: 1rem;
  }
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.error-message {
  flex-wrap: nowrap !important;
}
.error-message a {
  color: white !important;
  text-decoration: underline !important;
}

.form-control-error {
  color: #a81616;
  font-weight: 700;
  font-size: 14px;
}

html,
body,
.iw_viewport_runtime-wrapper,
#root,
#__next {
  height: 100%;
}

.iw_viewport_runtime-wrapper {
  display: flex;
  flex-direction: column;
}

.iw_viewport_runtime-wrapper > div {
  flex: 1 0 auto;
}

.iw_viewport_runtime-wrapper > div:last-child {
  flex: unset;
  flex-shrink: 0;
}
.iw_viewport_runtime-wrapper > div:first-child {
  flex: 0 0 auto;
}

.iw_viewport_runtime-wrapper
  > div:last-child
  > .MuiGrid-container
  > .MuiGrid-item {
  width: 100%;
}
/*------------------- Search bar-------------------*/

/*animation*/
.animate {
  animation-duration: 0.4s;
  -webkit-animation-duration: 0.4s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

@keyframes slideIn {
  0% {
    transform: translateY(-2rem);
    opacity: 0;
    -webkit-transform: translateY(-2rem);
    -moz-transform: translateY(-2rem);
    -ms-transform: translateY(-2rem);
    -o-transform: translateY(-2rem);
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
    -webkit-transform: translateY(0rem);
    -moz-transform: translateY(0rem);
    -ms-transform: translateY(0rem);
    -o-transform: translateY(0rem);
  }
  0% {
    transform: translateY(-2rem);
    opacity: 0;
    -webkit-transform: translateY(-2rem);
    -moz-transform: translateY(-2rem);
    -ms-transform: translateY(-2rem);
    -o-transform: translateY(-2rem);
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(-2rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
[data-test-id='main-nav'] > ul > li:not(:last-child) {
  margin-right: 0px;
}

[data-test-id='nav-link-broker-select'] {
  max-width: 150px;
}

[data-test-id='nav-link-profile'] {
  max-width: 150px;
}

@media (min-width: 1170px) {
  [data-test-id='profile-nav'] > ul > li:first-child {
    right: 370px;
  }
}
